/* noto sans */
@font-face {
    font-family: 'Noto-Black';
    src: url('./assets/fonts/NotoSansKR-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Noto-Bold';
    src: url('./assets/fonts/NotoSansKR-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Noto-Medium';
    src: url('./assets/fonts/NotoSansKR-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Noto-Regular';
    src: url('./assets/fonts/NotoSansKR-Regular.otf');
}

@font-face {
    font-family: 'Noto-Light';
    src: url('./assets/fonts/NotoSansKR-Light.otf') format('opentype');
}

/* futura  */
@font-face {
    font-family: 'Futura';
    src: url('./assets/fonts/FuturaStdBold.otf') format('opentype');
}

@font-face {
    font-family: 'Futura PT';
    src: url('./assets/fonts/Futura\ Heavy\ font.ttf') format('truetype');
}

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    /* 1rem = 10px */
    font-size: 100%;
    font-size: 16px;
}

@media all and (max-width: 1280px) {
    html{
        /* 1280px 너비의 16px 폰트 사이즈 기준 = (100vw / 1280px * 16px) */
        font-size: 1.25vw;
    }
}

body {
    min-width: 1024px;
    padding-right: 0px !important;
    font-family: 'Pretendard';
}

h1,
h2,
h3,
h4,
p {
    white-space: pre-line !important;
}
.white-space-nowrap {
    white-space: nowrap !important;
}
@media all and (max-width: 1024px) {
    body {
        min-width: initial;
    }

}

@media all and (max-width: 768px) {
    body,
    html{
        font-size: 16px !important; 
    }
    .text_nowrap {
        white-space: nowrap !important;
    }
    .text_center {
        text-align: center;
    }
    span {
        white-space: pre-line !important;
    }
}
@media all and (max-width: 400px) {
    body,
    html{
        font-size: 16px !important; 
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
    font-family: 'Noto-Light';
}
input::-moz-placeholder {
    font-family: 'Noto-Light';
}
input:-ms-input-placeholder {
    font-family: 'Noto-Light';
}
input:-moz-placeholder {
    font-family: 'Noto-Light';
}
input::placeholder {
    font-family: 'Noto-Light';
}

/* clear default a tag styles */
a {
    color: inherit;
    text-decoration: none;
}
a:visited {
    color: inherit;
    text-decoration: inherit;
}

.catalog-width{
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}

.restrict-width{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}